/* Grid.css */

.appis {
  min-height: 100vh;
  overflow-x: hidden;
}

.fixer {
  text-align: center;
  overflow-x: hidden;
  /* min-width: 100vw;  */
  background-color: transparent;
  /* background-image: url('./flife3.png');
  background-size: cover; */
  font-weight: 700;
  
  padding: 40px 0 100px 0;

}
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .heading h1 {
    margin-right: 25px;
  }
  .mover {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .slider {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* margin-right: 8px; */

  }
  .gridContainer {
    display: flex;
    justify-content: center;
    background: #000000;
    padding: 20px;
    border-radius: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(36, 1fr);
    gap: 5px;
    max-width: 90vw;
    margin: 0 auto;
  }

  .block {
    border-radius: 5px;
    color: brown;
    font-size: 0.8rem;
    height: 1.3rem;
    width: 1.3rem;
    text-align: center;
  }

  .button {
    background-color: #fff;
    padding: 7px 5px 7px 9px;
    border-radius: 8px;
    color: #fff;
    height: 20px;
    width: 20px;
    margin-top: 3px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .button2 {
    background-color: #fff;
    padding: 7px 7px 7px 7px;
    border-radius: 8px;
    color: #fff;
    height: 20px;
    width: 20px;
    margin-top: 3px;
    margin-left: 10px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .button img{
    height: 30px;
    width: 30px;

    margin: auto;
  }
  .button2 img{
    height: 30px;
    width: 30px;

    margin: auto;
  }

  .info{
    display: flex;
    justify-content: center;
    align-items: center;
    color:#000000;
    flex-direction: column;
    background-color: #FFFECB;
    border-radius: 25px;
    border: 1px solid #FFFECB;
    padding: 20px;

    margin-bottom: 20px;
  }
  .ospan{
    background-color: black;
    padding: 20px;
    margin-top: 20px;
    border-radius: 25px;
  }
  .info span{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: #fff;
    background-color: black;
    padding: 0 20px;
    /* margin-bottom: 5px; */
  }
  .info p{
    margin:0;
    font-weight: 700;
  }

  @media only screen and (max-width: 1200px) {
      .app{
        width:100vw;
      }
      .grid {
        display: grid;
        grid-template-columns: repeat(36, 1fr);
        gap: 3.5px;
        max-width: 90vw;
        margin: 0 auto;
      }
    
      .block {
        border-radius: 5px;
        color: brown;
        font-size: 0.8rem;
        height: 1.2rem;
        width: 1.2rem;
        text-align: center;
      }
  }

  @media only screen and (max-width: 850px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(36, 1fr);
        gap: 3px;
        max-width: 90vw;
        margin: 0 auto;
      }
    
      .block {
        border-radius: 5px;
        color: brown;
        font-size: 0.8rem;
        height: 1rem;
        width: 1rem;
        text-align: center;
      }
  }

  @media only screen and (max-width: 730px) {
    .grid {
        display: grid;
        grid-template-columns: repeat(36, 1fr);
        gap: 3.5px;
        max-width: 90vw;
        margin: 0 auto;
      }
      .info span{

        flex-direction: column;
      }
      .info span div{
        margin-top: 10px;
      }
    
      .block {
        border-radius: 2px;
        color: brown;
        font-size: 0.8rem;
        height: .7rem;
        width: .7rem;
        text-align: center;
      }
  }

  @media only screen and (max-width: 550px) {
    .gridContainer {
      display:flex;
      align-self: center;
      align-content: center;
      padding: 20px 0;
      justify-content: center;
      margin: 0;

    }
    .grid {
        display: grid;
        grid-template-columns: repeat(36, 1fr);
        gap: 3.5px;
        max-width: 90vw;
        margin: 0 ;
        
      }
      .mover {
 
        flex-direction: column;
      }
      .slider {
        flex-direction: row;
      }
      .block {
        border-radius: 1px;
        color: brown;
        font-size: 0.8rem;
        height: .4rem;
        width: .4rem;
        text-align: center;
      }
   


      .heading h1 {
        margin-right: 15px;
      }
  }


  

  @media only screen and (max-width: 400px) {
    .gridContainer {
      display:block;
      align-self: center;
      align-content: center;
      padding: 20px 10px;
      justify-content: center;
      margin: 0;

    }
  }