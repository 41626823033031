/* Auth.css */

body {
    background-image: url('./flife2.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* font-family: Arial, sans-serif; */
  }
  
  .container {
    display: flex;
    flex-direction: row;
    width: 100vw;
    align-items: center;
    justify-content: space-between;

    color: #fff;
  }
  .c1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-left:14rem ;
  }
  .c2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin-right: 27rem ;
    border: 2px solid #fff;
    border-radius: 25px;
    padding: 20px;
  }


  h1 {
    font-size: 4.5rem;
    font-weight: 700;

    margin-top:-15px;
    margin-bottom:-12px;
  }
  
  p {
    font-size: 1.3rem;
    max-width:600px;
    padding: 0 50px;
    text-align: center;
    font-weight: 500;
    /* margin-bottom: 30px; */
  }
  
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-left: 50px; */
    /* margin-bottom: 20px; */
  }
  
  .input {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 16px;
    width: 300px;
    border: none;
    border-radius: 4px;
    color: #333;
  }
  
  .buttona {
    padding: 12px 20px; /* Increased padding for a larger button */
    font-size: 18px; /* Slightly larger font size */
    cursor: pointer;
    background-color: #007BFF;
    color: #fff;
    align-self: center;
    border: none;
    border-radius: 8px; /* More rounded corners */
    margin-top: 10px;
    width: 100%; /* Full-width button to align with input fields */
    max-width: 300px; /* Limit max width for larger screens */
    transition: background-color 0.3s ease;

  }
  
  .buttona:hover {
    background-color: #0056b3; /* Darken on hover */
  }
  .toggle-text {
    margin-top: 15px;
    font-size: 0.9rem;
  }
  
  .toggle-link {
    color: #4497ef;
    cursor: pointer;
    text-decoration: underline;
  }

  @media (max-width: 1600px) {
    .c1 {
 
        margin-left: 8rem ;
      }
      .c2 {

        margin-right: 15rem ;

      }
}
  

  @media (max-width: 1200px) {
    h1{
      font-size: 3rem;
    }
    p {
      font-size: 1.1rem;
    }
    .c1 {
      margin-left: 5rem ;
    }
    .c2 {
      margin-right: 5rem ;
    }
  }
  
  @media (max-width: 900px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
      padding: 0 30px;
    }

    .c1 {
        margin-top: 50px;
      margin-left: 0;
    }
    .c2 {
      margin: 50px 0;
      margin-right: 0;
    }

  }
  